import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { languages } from "@/constants";
import Link from "next/link";
import Image from "next/image";
import { Popover, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";

const LanguageDropdown = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const langRef = useRef<HTMLDivElement>(null);
	const router = useRouter();
	const selectedLocale = languages.find(lang => lang.locale === router.locale)!;

	if (!router.locale) return null;

	return (
		<div className="items-center justify-center gap-[10px] z-[800] flex-col">
			<Popover open={dropdownOpen}>
				<PopoverTrigger>
					<p ref={langRef}
						className="language-btn-gradient py-[14px] px-3 text-white rounded-lg text-[16px] flex items-center cursor-pointer select-none gap-1"
						onClick={() => { setDropdownOpen(true); }}
					>
						{selectedLocale.name.split("(")[0]}
						<Image alt="Down caret" src={"/caret-down.svg"} height={18} width={18} />
					</p>
				</PopoverTrigger>
				<PopoverContent onInteractOutside={() => { setDropdownOpen(false); }}>
					<div className="bg-[#fff] rounded-xl z-50 overflow-clip m-3">
						<div className="max-h-[60vh] overflow-y-scroll scrollbar-thin scrollbar-track-[#edeff4] scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-thumb-[#d8dfee] divide-y">
							{languages.map((data, key) => (
								<Link key={key} href={router.pathname} locale={data.locale} onClick={() => { setDropdownOpen(false); }}
									className="w-[300px] flex items-center mx-4 border-[#E5EAF6] py-1 cursor-pointer"
								>
									<input
										type="checkbox"
										className="w-[16px] h-[16px] rounded-[6px] border-[2px] border-[#c4c4c4] cursor-pointer mr-1"
										defaultChecked={data.locale === selectedLocale.locale}
										onChange={(e) => { console.log(e); }}
									/>
									<div className="flex justify-between items-center w-full">
										<p className="p-3 text-[16px] cursor-pointer select-none">
											{data.name}
										</p>
									</div>
								</Link>
							))}
						</div>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default LanguageDropdown;
