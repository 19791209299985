export const websiteSchemaJSON = {
	"@context": "https://schema.org",
	"@type": "WebSite",
	name: "Rent80",
	alternateName: "Rent80",
	legalName: "Rent80",
	url: "https://www.rent80.com/",
	sameAs: [
		"https://www.facebook.com/rent80rentals/",
		"https://twitter.com/rent80rentals",
		"https://www.youtube.com/@rent8026",
		"https://www.rent80.com/",
		"https://www.instagram.com/rent80rentals/",
	],
};

export const orgSchemaJSON = {
	"@context": "https://schema.org",
	"@type": "OnlineBusiness",
	name: "Rent80",
	url: "https://www.rent80.com",
	logo: "https://www.rent80.com/logo.jpeg",
	sameAs: ["https://rent80.com"],
	description:
		"Rent80 compare the rental car prices from all major car rental providers such as sixt, avis, dollar, budget and kayak. Rent80 great selection and prices on rental cars.",
	address: {
		"@type": "PostalAddress",
		streetAddress: "43 Al Fahidi St",
		addressLocality: "Dubai",
		addressCountry: "AE",
		addressRegion: "Al Fahidi",
		postalCode: "122002",
	},
	aggregateRating: {
		"@type": "AggregateRating",
		ratingCount: 800,
		ratingValue: 4.2,
	},
	contactPoint: {
		"@type": "ContactPoint",
		email: "hello@rent80.com",
		// telephone: "--------", // FIXME: Add phone number
	},
	numberOfEmployees: {
		"@type": "QuantitativeValue",
		value: 25,
	},
	foundingDate: "2021-01-01T10:10:00.000Z",
	// vatID: "",
	// taxID: "",
};

