import { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { VscChromeClose } from "react-icons/vsc";
import { useMediaQuery } from "usehooks-ts";
import { languages } from "@/constants";

type LanguageDropdownProps = { className?: string | undefined; };

export default function LanguageDropdown({ className }: LanguageDropdownProps) {
	const { locale, replace, pathname } = useRouter();
	const [open, setOpen] = useState(false);
	const isDesktop = useMediaQuery("(min-width: 768px)");
	const toggleOpen = () => { setOpen(open => !open); };
	const triggerClassName = "p-2 rounded-lg text-my-dark-blue bg-light-400";
	const currentLang = languages.find(l => l.locale === locale);

	useEffect(() => {
		if (open) document.body.classList.add("overflow-hidden");
		else document.body.classList.remove("overflow-hidden");
	}, [open]);

	const localeSwitchList = languages.map((lang) => {
		const checked = (locale === lang.locale);

		return (
			<button type="button" key={lang.locale} className="flex gap-4 items-center py-3 w-full"
				onMouseUp={e => e.preventDefault()}
				onMouseDown={() => {
					toggleOpen();
					replace(pathname, undefined, { locale: lang.locale });
				}}
			>
				<Image src={checked ? "/checked-box.svg" : "/unchecked-box.svg"} alt={checked ? "Checked" : "Unchecked"} width={16} height={16} />
				{lang.name}
			</button>
		);
	});

	return isDesktop ? (
		<div className={className}>
			<div className="flex gap-2 mx-auto w-fit">
				<Popover open={open}>
					<PopoverTrigger className={triggerClassName} onClick={toggleOpen}>
						{currentLang?.name ?? "Select Language"}
					</PopoverTrigger>
					<PopoverContent onInteractOutside={() => { setOpen(false); }}>
						<div className="flex flex-col m-1 min-w-[300px] bg-white rounded-xl text-my-dark-blue gap-1 drop-shadow-lg z-50">
							<PopoverClose className="p-2 m-2 mb-0 w-fit" onClick={toggleOpen}>
								<VscChromeClose />
							</PopoverClose>
							<div className="flex flex-col px-4 divide-y-[1px] text-my-dark-blue max-h-96 overflow-y-scroll">
								{localeSwitchList}
							</div>
						</div>
					</PopoverContent>
				</Popover>
			</div>
		</div>
	) : (
		<div className={className}>
			<div className="flex gap-2 mx-auto w-fit">
				<button className={triggerClassName} onClick={toggleOpen}>
					{currentLang?.name ?? "Select Language"}
				</button>
				{open &&
					<div className="flex fixed right-0 bottom-0 left-0 flex-col justify-end w-screen h-screen text-base text-black bg-black bg-opacity-50" onClick={toggleOpen}>
						<div className="bg-white rounded-t-xl shadow-lg">
							<VscChromeClose className="m-3" />
							<div className="overflow-y-scroll p-2 max-h-[75vh] rounded-lg">
								{localeSwitchList}
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
};
