import { observer } from "mobx-react-lite";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head";
import classNames from "classnames";
import LanguageDropdown from "./LanguageDropdown";
import { orgSchemaJSON, websiteSchemaJSON } from "./schemas";
import { useRouter } from "next/router";

type NavBarProps = { bg: boolean; infoPage?: boolean; };

const Navbar = ({ bg, infoPage }: NavBarProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        {typeof window !== "undefined" && (
          <>
            <script type="application/ld+json">
              {JSON.stringify(websiteSchemaJSON)}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(orgSchemaJSON)}
            </script>
          </>
        )}
      </Head>
      <nav className={classNames("hidden relative z-10 items-center w-full lg:flex h-[81px]", { "navbar-gradient": bg })}>
        <div className="flex justify-between items-center px-3 mx-auto w-full max-w-7xl py-auto lg:py-6">
          <Link href={"/"}>
            <Image
              src="/logo.svg"
              height={47}
              width={166}
              alt="Rent80 logo"
              className="hidden lg:block"
            />
          </Link>
          {(bg && router.pathname === "/lp/3") && <LanguageDropdown />}
        </div>
      </nav>
      <nav
        className={classNames("block w-full lg:hidden", { "navbar-gradient": infoPage })}>
        <div className="max-w-7xl mx-auto flex justify-start py-5 w-full px-[13px]">
          <Link href={"/"}>
            <img
              src="/logo.svg"
              height={18}
              width={78}
              alt="Rent80 logo"
              className="block lg:hidden"
            />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default observer(Navbar);
